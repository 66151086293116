import React, { useRef, useState } from 'react';
import '../../assets/scss/components/input-pin.scss';
import PropTypes from 'prop-types';
import screenReader from '../../utils/screenReader';

const InputPin = ({ number, removeComponent }) => {
  const [pin, setPin] = useState('');
  const [name, setName] = useState('');
  const pinRef = useRef(null);
  const pinInput = pinRef.current;
  const nameRef = useRef(null);
  const [error, setError] = useState('valid');

  screenReader();

  const validatePin = (value) => {
    if (!value) {
      setError('Required');
      pinRef.current.classList.add('is-invalid');
    } else if (!/^[A-Z0-9]{4}-[A-Z0-9]{3}$/i.test(value)) {
      setError('Invalid pin format (XXXX-XXX)');
      pinInput.classList.add('is-invalid');
    } else {
      setError('valid');
      pinInput.classList.remove('is-invalid');
    }
  };

  const handlePinChange = (e) => {
    const inputValue = e.target.value;
    const sanitizedValue = inputValue.replace(/-/g, '');
    const formattedValue = sanitizedValue.replace(/(.{4})/, '$1-');
    setPin(formattedValue);
  };

  return (
    <div className="input-ornament" id={'input_ornament_' + number}>
      <div className={'label-input-ornament sc-r'} role="heading" aria-level="2">
        Enter your ornament’s Safe Scan PIN
        {number !== 0 && (
          <a href="#" onClick={(e) => removeComponent(e, number)} aria-label="close filed ornament">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                fill="#605D64"
              />
            </svg>
          </a>
        )}
      </div>
      <p>
        The Safe Scan PIN is a 7 digit alphanumeric code found on the back of your ornament and may contain the numbers
        0 or 1 and the letters O or I.
      </p>
      <div className={`input-text ${error === 'valid' ? '' : 'box-invalid'}`}>
        <input
          ref={pinRef}
          onBlur={(e) => validatePin(e.target.value)}
          onChange={handlePinChange}
          value={pin}
          name={`pin_${number}`}
          type="text"
          placeholder={'XXXX-XXX'}
          id={`pin_${number}`}
          className={'pin'}
          required={true}
          autoFocus={true}
          maxLength={8}
        />
        <div className="invalid-feedback">{error}</div>
        <label htmlFor={`pin_${number}`} className="">
          PIN Code
        </label>
      </div>
      <div className={'label-input-ornament sc-r'} role="heading" aria-level="2">
        Create a name for your ornament
      </div>
      <p>
        Enter your child’s name or give your ornament a special name. To enter multiple names, separate each with a
        comma.
      </p>
      <div className="input-text">
        <input
          ref={nameRef}
          onChange={(e) => setName(e.target.value)}
          value={name}
          name={`name_${number}`}
          type="text"
          placeholder={'Name'}
          id={`name_${number}`}
          className={'name'}
          maxLength={20}
        />
        <label htmlFor={`name_${number}`} className="">
          Ornament Name
        </label>
      </div>
    </div>
  );
};

export default InputPin;

InputPin.propTypes = {
  number: PropTypes.number,
  removeComponent: PropTypes.func,
};
