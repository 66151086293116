import React from 'react';
import '../assets/scss/pages/registration-form.scss';
import '../assets/scss/pages/user-verify.scss';
import '../assets/scss/pages/message-page.scss';
import '../assets/scss/pages/maximum-devices.scss';
import FormButton from '../components/user/FormButton';
import { INDEX_ROUTE, USER_FORGET_DEVICE_ROUTE } from '../utils/consts';
import imgLabel from '../assets/images/snow.svg';
import { Link } from 'react-router-dom';

const UserMessageMaximumNumberDevices = () => {
  // const { adminStore } = useContext(Context);
  // const numberDevices = adminStore.numberDevices || '5';

  return (
    <div className={'registration-form user-verify maximum-devices'}>
      <div className="panel-top">
        <div className="link-info">
          <Link to={INDEX_ROUTE}>Cancel</Link>
        </div>
        <div className="fa-3x  text-center txt-red hide-mobile icon-warning">
          <i className="fas fa-circle-exclamation"></i>
        </div>
        <img src={imgLabel} alt="icon" />
      </div>
      <div className="message-page">
        <div className={'icon-top'}>
          <div className="fa-3x  text-center txt-red show-mobile icon-warning">
            <i className="fas fa-circle-exclamation"></i>
          </div>
          <h2 className={'title-label'}>You’ve reached the maximum number of devices</h2>
          <span className={'txt-red'}>
            Each ornament is limited{/* to {numberDevices} devices */}.{' '}
            <b>You have reached the maximum number of devices for this ornament.</b>{' '}
          </span>
          <br />
          <span className={'txt-red'}>To continue logging in, forget a device.</span>
          <div className={'block-button'}>
            <FormButton path={USER_FORGET_DEVICE_ROUTE} color={'red'} name={'Forget device'} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserMessageMaximumNumberDevices;
